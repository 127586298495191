<template>
  <div class="page-gray" style="padding-top: 1px">

    <div class="form">
      <!--            <van-field-->
      <!--                class="form-item"-->
      <!--                :class="{disabled: type === 'add'}"-->
      <!--                readonly-->
      <!--                :disabled="type === 'add'"-->
      <!--                v-model="form.school"-->
      <!--                label="学校"-->
      <!--                placeholder="请选择学校"-->
      <!--                @click="type === 'add' ? void 0 : enterSchoolCode = true"-->
      <!--                :right-icon="type === 'edit' ? 'arrow' : ''"-->
      <!--                v-if="type !== 'add'"-->
      <!--            />-->
      <div
          class="form-item"
          :class="{disabled: type === 'add'}"
          @click="type === 'add' ? void 0 : enterSchoolCode = true"
      >
        <div class="van-field__label">
          学校
        </div>

        <van-notice-bar
            background="rgba(0,0,0,0)"
            style="flex: 1;padding: 0; color: #666;"
            scrollable
            :speed="30"
            :mode="type == 'add' ? '' : 'link'"
            text="门店默认"
        />
      </div>

      <van-field class="form-item " v-model="form.name" label="学生姓名" placeholder="请输入学生姓名"/>
<!--      <van-field class="form-item " readonly :value="form.gradeName" @click="gradePicker = true" label="年级"-->
<!--                 placeholder="请选择所在年级" right-icon="arrow-down"/>-->
<!--      <van-field class="form-item " readonly :value="form.className" @click="classPicker = true" label="班级"-->
<!--                 placeholder="请选择所在班级" right-icon="arrow-down"/>-->
      <van-field class="form-item " type="tel" v-model="form.phone" label="家长电话" placeholder="请输入手机号码"/>

<!--      <div class="tips">温馨提示：学校、年级、班级、电话信息是校服能及时准确配送的前提，请填写完整、准确。</div>-->
    </div>

    <div class="btn" v-if="type === 'add'">
      <van-button type="primary" block @click="submit">确认</van-button>
    </div>


<!--    <div class="btn" v-if="type === 'edit'">-->
<!--      <van-button type="primary" @click="reset">恢复默认</van-button>-->
<!--      <van-button type="primary" color="#D53A26" @click="submit">提交修改</van-button>-->
<!--    </div>-->


    <van-popup v-model="gradePicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="gradeList"
          title="选择年级"
          @cancel="gradePicker = false"
          @confirm="onGradeConfirm"
      />
    </van-popup>

    <van-popup v-model="classPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="!form.grade ? [] : clastsList"
          title="选择班级"
          @cancel="classPicker = false"
          @confirm="onClassConfirm"
      />
    </van-popup>


    <van-popup v-model="enterSchoolCode" round :style="{ width: '80vw' }"
               @close="schoolCodeState = null; schoolCode = ''">
      <div class="enterSchoolCode">
        <div class="title">切换到新学校</div>
        <div class="input">
          <van-field
              v-model="schoolCode"
              placeholder="请输入学校编码"
          />
          <span class="state" v-if="schoolCodeState === false">编码错误</span>
          <span class="state" v-if="schoolCodeState === true" style="color: #07c160;">编码正确</span>
        </div>

        <div class="tips">如忘记学校编码，可咨询学校、班主任或其他家长。</div>

        <div class="buttonGroup">
          <div class="btnItem" @click="enterSchoolCode = false">取消</div>
          <div class="btnItem primary" @click="submitSchool">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import Vue from 'vue'
// import imageCrop from '@/components/imageCrop'

export default {
  name: "StudentHandle",
  components: {
    // imageCrop
  },
  data() {
    return {
      enterSchoolCode: false,
      schoolCodeState: null,
      schoolCode: '',  //学校编码
      uploadLoading: false,
      storeId:'',//门店
      gradePicker: false,
      gradeList: [],
      classPicker: false,
      clastsList: [],

      type: '', //新增或者修改
      studentId: '', // 为修改的时候 学生ID
      form: {
        photo: '',
        name: '',
        grade: '',
        classn: '',
        phone: '',
        school: '',
        schoolId: '',
        gradeName:'',
        className:'',
        studentId:'',
      },

      form_backups: {}, //备份 用来恢复
    }
  },
  methods: {
    imgChange() {
      let file = this.$refs.imgFile.files[0]
      // TODO Send Server
      this.$toast.loading({duration: 0, text: '上传中', forbidClick: true});

      setTimeout(() => {
        console.log(file);
        this.$toast.loading({duration: 1})

        //下面的代码是转为base64, 为了演示用 ，正常的开发应该是直接把文件上传到服务器， 服务器返回一个文件http地址， 然后把地址赋值给this.form.phot即可
        let fileRead = new FileReader()
        fileRead.onload = () => {
          this.form.photo = (fileRead.result)
        }
        fileRead.readAsDataURL(file)


      })
    },
    onGradeConfirm(item) {
      // item => { text: '高中一年级', value: 1}
      this.form.grade = item.value;
      this.form.gradeName = item.text;
      this.gradePicker = false
      this.$http.get(this.baseMallUrl + `/h5_mall/queryClassByGradeId/${item.value}`).then(res => {
        let classList = res.data;
        let arrClassn = [];
        for (let index in classList) {
          let classn = classList[index];
          arrClassn.push({text: classn.name, value: classn.id});
        }
        this.clastsList = arrClassn;
      })
    },
    onClassConfirm(item) {
      this.form.classn = item.value;
      this.form.className = item.text;
      this.classPicker = false
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.form_backups))
    },
    submit() {

      if (!this.form.name) {
        this.$toast('请输入学生姓名');
        return;
      }
      if (this.form.name.length > 8) {
        this.$toast('学生姓名不能超过8个字符');
        return;
      }
      let nameReg = /[`~!@#$^\-&*()=|{}':;',\\[\].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
      if (nameReg.test(this.form.name)) {
        this.$toast('请输入正确的姓名');
        return;
      }
      // if (!this.form.grade) {
      //   this.$toast('请选择所在年级');
      //   return;
      // }
      //
      // if (!this.form.classn) {
      //   this.$toast('请选择所在班级');
      //   return;
      // }
      if (!this.form.phone) {
        this.$toast('请输入手机号码');
        return;
      }
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.form.phone)) {
        this.$toast('请输入正确的手机号码');
        return;
      }

      let form = this.form;
      form.userId = Vue.prototype.userId;
      form.deptId = Vue.prototype.deptId;
      form.storeId = this.storeId;
      // TODO Send Server

      if (this.type === 'add') {
        this.$http.post(this.baseMallUrl + `/h5_store/saveStudentInfo`, form, {emulateJSON: true}).then(() => {
          this.$toast.success('新增成功');
          this.$router.replace(`/store/index/${this.storeId}`)
        })
      }

    },

    submitSchool() {
      if (!this.schoolCode) {
        this.$toast({message: '请输入学校编码'})
        return;
      }

      let codeRule = /^[0-9]{4}$/;

      if (!codeRule.test(this.schoolCode)) {
        this.schoolCodeState = false
        this.$toast({message: '必须为4位数字'})
        return;
      }

      this.schoolCodeState = true;

      this.$toast.loading({duration: 0, forbidClick: true});
      this.$http.get(this.baseMallUrl + `/h5_mall/querySchoolCode/${this.schoolCode}/${Vue.prototype.deptId}`).then(res => {
        this.$toast.loading({duration: 1, forbidClick: true});
        this.form.school = res.data.name;
        this.enterSchoolCode = false
      })
      /*   setTimeout(() => {
           this.$toast.loading({duration: 1, forbidClick: true});
           this.form.school = 'xxx学校'
           this.enterSchoolCode = false
         }, 1000)*/

    },
    beforeRead(file, callback) {
      const isImgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isImgType) {
        this.$toast("上传图片只能是 JPG或PNG 格式!");
        callback(false);
        return false;
      }
      if (!isLt10M) {
        this.$toast("上传图片大小不能超过 10MB!");
        callback(false);
        return false;
      }
      callback(true);
      return true;
    },
    // 裁剪点击确定
    cropUpload(file) {
      this.uploadLoading = true;
      file.status = "uploading";
      file.message = "上传中...";
      let form = new FormData();

      form.append('file', file.file);

      this.$http.post(this.baseMallUrl + `/h5_mall/upload`, form,
          {headers:{"content-type":  "multipart/form-data"}}).then((res) => {
        if (res.data) {
          file.status = "done";
          file.message = "上传完成";
          this.uploadLoading = false;
          this.form.photo = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.uploadLoading = false;
          this.$toast(res.data);
        }
      });
    },
  },

  mounted() {

    this.type = this.$route.meta.type;
    this.storeId = this.$route.query.storeId;
    // let school = this.$route.query.schoolObj;

    // let id='';
    // if(school){
    //   this.form.school = school.name;
    //   this.form.schoolId = school.id;
    //   id = school.id
    // }else{
    //   id =  this.$route.query.schoolId;
    // }
    //
    // this.$http.get(this.baseMallUrl + `/h5_mall/queryGradeBySchoolId/${id}`).then(res => {
    //   let gradeList = res.data;
    //   let arrGrade = [];
    //   for (let index in gradeList) {
    //     let grade = gradeList[index];
    //     arrGrade.push({text: grade.name, value: grade.id});
    //   }
    //   this.gradeList = arrGrade;
    // })

    if (this.type === 'add') {
      // 获取当前选择的学校
    } else {
      this.studentId = this.$route.params.id
      // TODO send Server
      this.$http.get(this.baseMallUrl + `/h5_mall/queryStudentInfo/${this.studentId}`).then(res => {
        let studentInfo = res.data;
        this.form = {
          photo: studentInfo.photo,
          name: studentInfo.receiverName,
          grade: studentInfo.gradeId,
          classn: studentInfo.classId,
          gradeName: studentInfo.grade,
          className: studentInfo.classn,
          phone: studentInfo.phone,
          schoolId:studentInfo.schoolid,
          school: studentInfo.school,
        }
        this.form_backups = JSON.parse(JSON.stringify(this.form))
      })

    }


  }
}

</script>
<style lang="less" scoped>
.uploadImg {
  height: 70px;
  width: 120px;
  border-radius: 50%;
  margin: 17px auto 44px auto;
  position: relative;
  text-align: center;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  > img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  span {
    line-height: 11px;
    font-size: 11px;
    margin-top: 3px;
    color: #666666;
  }

  .add {
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -4px;
    right: -2px;

    > img {
      height: 12px;
    }
  }
}

.form {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 29px 20px 24px 20px;
  margin: 0 18px;

  .form-item {
    height: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    margin-bottom: 20px;

    &.disabled {
      background: #F4F4F4;
    }


  }

  .tips {
    font-size: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FF0000;
    line-height: 15px;
  }
}

.btn {
  padding: 20px 18px 30px 18px;
  display: flex;

  .van-button {
    flex: 1;
  }

  .van-button + .van-button {
    margin-left: 16px;

  }
}


.enterSchoolCode {
  padding: 27px 26px;
  background: #fff;

  .title {
    line-height: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #212121;
  }

  .input {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .van-cell {
      padding-left: 0;
      padding-right: 0;

      &::after {
        border-color: #ccc !important;
        left: 0;
        right: 0;
      }
    }

    .state {
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #FF0000;
      display: inline-block;
      width: 80px;
      padding-left: 11px;
    }
  }

  .tips {
    line-height: 10px;
    font-size: 10px;
    font-weight: 400;
    color: #FF0000;
    margin: 22px 0 33px 0;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;

    .btnItem {
      width: 65px;
      height: 29px;
      background: #FFFFFF;
      border: 1px solid #666666;
      border-radius: 5px;
      text-align: center;
      line-height: 26px;

      font-size: 15px;
      font-weight: 400;
      color: #666666;

      &.primary {
        border-color: #FF553F;
        background-color: #FF553F;
        color: #fff;
        margin-left: 16px;
      }
    }
  }
}


::v-deep .van-field__label {
  width: 65px;
  margin-right: 20px;
  font-size: 14px;
  color: #212121;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
  text-align-last: justify;

  &::after, &:before {
    display: inline-block; /*行内元素*/
    content: '';
    width: 100%; /*可以挤掉文字，保证不跟文字在同一行*/
    height: 0;
    visibility: hidden;
  }
}

::v-deep .van-field__control {
  font-size: 14px;
}

::v-deep .van-field__control:disabled {
  color: #666;
  -webkit-text-fill-color: #666;
}
</style>
